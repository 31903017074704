.Header{
     position: fixed;
    height: 5rem;
    width: 100vw;
    padding: 16px 78px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    color: black;
    /* gap: 2rem; */
    align-items: center;
    border: 1px solid rgba(0,0,0,.1);
    z-index: 9;
    top: 0;
    box-shadow: 1px 3px 3px -1px rgba(0,0,0,0.56);
-webkit-box-shadow: 1px 3px 3px -1px rgba(0,0,0,0.56);
-moz-box-shadow: 1px 3px 3px -1px rgba(0,0,0,0.56);

    
}

.logo{
    display: flex;
    flex-direction: column;
     width: 30%;
}

.logop1{
    font-weight: 800;
    font-size: 2.8rem;
    margin-top: 0;
    letter-spacing: -0.2;
    /* width: 150px; */
    -webkit-font-smoothing: antialiased;

    background-image: -webkit-linear-gradient(181deg,#0048ff,#0096ff),-webkit-linear-gradient(#000,#000);
    background-image: -o-linear-gradient(181deg,#0048ff,#0096ff),-o-linear-gradient(#000,#000);
    background-image: linear-gradient(269deg,#0048ff,#0096ff),linear-gradient(#000,#000);
    color: #0084ff;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.logop2{
    object-fit: contain;
    font-family: Raleway,sans-serif;
    font-size: 1em;
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
    line-height: .8;
    letter-spacing: .9px;
    color: #000;
}

.list{
    position: absolute;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:8%;
    height: 100%;
    margin: 0;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: .9px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #efefef;
    left: 20%;
    
}

.list>div:hover{
    color: #0048ff;
}



.hpara{
    width: 25%;
    font-size: 200;
    color: gray;
}

.Hbtn{
    padding: 10px;
    width: 20%;
    border: 1px solid white;
    width: auto;
    font-size: 0.75em;
    padding: 0.5em 2em;
    margin: 0px 1em 0px 0px;
    box-shadow: none;
    border-radius: 20%;
        height: 40px;
    min-width: 100px;
    word-wrap: break-word;
    word-wrap: break-word;
    border-radius: 20px;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
        color: #fff;
    outline: none;
    font-family: Raleway,sans-serif;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: .03em;
        background: linear-gradient(269deg,#0048ff,#0096ff);


    }


    @media screen and (max-width:800px){

        .logo{
            width: 100%;
           
        }

        .logop1{
            font-size: 40px;
            font-weight: 600;
        }
        .list{
            display: none;
        }
        .hpara{
            display: none;
        }
        .Hbtn{
            display: none;
        }

    }


    @media screen and (max-width:900px){

        .hpara{
            display: none;
        }
        .Hbtn{
            display: none;
        }
        .list{
            left: 40%;
        }
    
    }

