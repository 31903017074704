@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,400;0,600;0,800;1,200&display=swap');

*{
  
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  
}


.App{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
