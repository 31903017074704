.cardcontainer{
    margin-top: 10px;
    margin-bottom: 10px;
    height:auto;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    gap:50px ;
    flex-wrap: wrap;
   

}

.cardbox{
    height: auto;
    width:28%;
    box-shadow: -1px 0px 8px 2px rgba(0,0,0,0.42);
    -webkit-box-shadow: -1px 0px 8px 2px rgba(0,0,0,0.42);
    -moz-box-shadow: -1px 0px 8px 2px rgba(0,0,0,0.42);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    object-fit: fill;


    border-top-left-radius: 2% ;
    border-top-right-radius:  2%;
    border-bottom-left-radius: 2% ;
    border-bottom-right-radius:  2%;
}

.cardbox:hover{
    box-shadow: -1px 0px 8px 2px rgba(0,0,0,0.72);
}




img{
    height:200px;
    width: 100%;
      border-top-left-radius: 2% ;
    border-top-right-radius:  2%;
}

.infoBox{
    padding: 15PX;
}


.cardbox h3{
   padding-bottom: 8px;
   font-weight: 600;
   font-size: 28px;
   letter-spacing: 2px;
   font-family: Raleway;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
   
}
.authdate{
    color: #0096ff;
}
.auth{
    font-size: .9em;
    font-weight: 400;
    line-height: 2;
    color: #0096ff;
}

.date{
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    color: #000;
    margin-top: 0.3em;
}
.description{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: .35px;
    text-align: left;
    color: #000;
    margin: 0.3em 0;
    white-space: pre-wrap;
}





@media screen and (max-width:1000px){

    .cardbox{
        
        width:45%;  
    }


}

@media screen and (max-width:700px){

    .cardbox{
        
        width:100%;  
    }


}



